<template>
  <v-card class="mt-5">
    <v-expansion-panels accordion>
      <v-expansion-panel v-for="(item, i) in this.data" :key="i">
        <v-expansion-panel-header class="title primary-color-1--text">{{
          item.key
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-iterator
            :items="item.values"
            item-key="name"
            hide-default-footer
          >
            <template v-slot:default="{ items }">
              <div v-for="request in items" :key="request.id">
                <v-row class="px-2">
                  <v-col cols="6" sm="6" md="2" xl="2">
                    <span class="font-weight-medium black-3--text"
                      >Lote {{ request.batch.batchNumber }}</span
                    >
                    <br />
                    <span class="black-4--text">Doador/parceiro:</span>
                    <br />
                    <span class="black-4--text">
                      {{ request.sourceName }}
                      <br />
                      <em>
                        <a
                          class="secondary-color-1--text"
                          v-if="request.phones.length"
                          :href="`tel:${parsePhone(request.phones[0])}`"
                          >{{ parsePhone(request.phones[0]) }}</a
                        >
                        <span v-if="!request.phones.length">não informado</span>
                      </em>
                    </span>
                  </v-col>
                  <v-col cols="6" sm="6" md="2" xl="2">
                    <span class="font-weight-medium black-4--text"
                      >Tipo {{ request.type.description }}</span
                    >
                    <br />
                    <div
                      v-if="isPEV(request) || request.requesterType === 'PJ'"
                    >
                      <span class="black-4--text">Responsável:</span>
                      <br />
                      <span class="black-4--text">
                        {{ request.agent.name }}
                        <br />
                        <em class="secondary-color-1--text">
                          <a
                            class="secondary-color-1--text"
                            v-if="request.agent.phones.length"
                            :href="`tel:${parsePhone(request.agent.phones[0])}`"
                            >{{ parsePhone(request.agent.phones[0]) }}</a
                          >
                          <span v-if="!request.agent.phones.length"
                            >não informado</span
                          >
                        </em>
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6" md="3" xl="3">
                    <span class="font-weight-medium black-4--text"
                      >Informações</span
                    >
                    <address-info :address="request.address" color="black-4" />
                  </v-col>
                  <v-col cols="6" sm="6" md="2" xl="2">
                    <div v-if="isPEV(request)">
                      <span class="font-weight-medium black-4--text"
                        >Funcionamento:</span
                      >
                      <br />
                      <span class="black-4--text">
                        {{ request.businessHours }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="8" sm="5" md="3" xl="3">
                    <v-btn
                      @click="openStartCollectionDialog(request)"
                      :disabled="
                        !isAwaitCollectionSituation(request.situation.id)
                      "
                      color="secondary-color-1"
                      class="mx-auto d-block btn-max-content next-step-button white--text"
                      >{{ labelBtn(request) }}</v-btn
                    >
                    <div class="my-2 text-center">
                      <span class="black-4--text">Status da solicitação</span>
                    </div>
                    <field
                      class="mx-auto d-block"
                      @change="
                        situationId =>
                          openChangeSituationDialog(situationId, request.id)
                      "
                      v-model="request.situation.id"
                      type="select"
                      :items="selectSituations"
                      item-text="descricao"
                      item-value="id"
                      label="Situação"
                      name="situation"
                      :isRequired="true"
                      :disabled="!isInProgress(request.situation.id)"
                    />
                  </v-col>
                </v-row>
                <v-divider
                  v-if="!item.values[item.values.length - 1]"
                ></v-divider>
              </div>
            </template>
          </v-data-iterator>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-if="changeSituationDialogState">
      <change-situation-dialog
        :requestId="requestSelected.id"
        :statusSelected="requestSelected.status"
        :backBtn="backBtnChangeSituationDialog"
        :state="changeSituationDialogState"
        @updateList="$emit('updateList')"
      />
    </div>
    <div v-if="startCollectionDialogState">
      <start-collection-dialog
        @startCollection="startCollection"
        :backBtn="() => (startCollectionDialogState = false)"
        :request="requestSelected"
        :state="startCollectionDialogState"
      />
    </div>
  </v-card>
</template>
<script>
  import { mapActions } from "vuex";

  import masksMixin from "@/modules/core/mixins/masksMixin";
  import Field from "@/modules/core/views/components/Field";
  import { parseAddress } from "@/modules/core/helpers/addressHelpers";
  import ChangeSituationDialog from "@/modules/collection/views/components/ChangeSituationDialog";
  import StartCollectionDialog from "@/modules/collection/views/components/StartCollectionDialog";
  import { requestTypes } from "@/modules/agenda/enums/requestTypes.enum";
  import {
    requestSituation,
    optionsRequestSituationToChange
  } from "@/modules/agenda/enums/request-situation.enum";
  import AddressInfo from "@/modules/core/views/components/AddressInfo";

  export default {
    name: "TableScheduleDetail",
    mixins: [masksMixin],
    props: {
      data: {
        required: true,
        type: Array
      }
    },
    components: {
      Field,
      ChangeSituationDialog,
      StartCollectionDialog,
      AddressInfo
    },
    data: () => ({
      requestSelected: null,
      changeSituationDialogState: false,
      startCollectionDialogState: false
    }),

    computed: {
      selectSituations() {
        return Object.values(optionsRequestSituationToChange);
      }
    },
    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("agenda", ["getRequestById", "changeSituationRequest"]),

      getAddress(address) {
        return parseAddress(address);
      },

      isPEV(request) {
        return request.type.id === requestTypes.PEV.id;
      },

      isAwaitCollectionSituation(situationId) {
        return requestSituation.WAITING_COLLECTION.id === situationId;
      },

      labelBtn(request) {
        return this.isAwaitCollectionSituation(request.situation.id)
          ? "Iniciar coleta"
          : request.situation.description;
      },

      isInProgress(situationId) {
        return requestSituation.IN_PROGRESS.id === situationId;
      },

      backBtnChangeSituationDialog() {
        this.changeSituationDialogState = false;
        this.$emit("updateList");
      },

      openChangeSituationDialog(statusSelectedId, requestId) {
        this.changeSituationDialogState = true;
        this.requestSelected = {
          id: requestId,
          status: statusSelectedId
        };
      },

      openStartCollectionDialog(request) {
        this.startCollectionDialogState = true;
        this.requestSelected = request;
      },

      async startCollection(id) {
        try {
          const payload = {
            id,
            payload: {
              id: requestSituation.IN_PROGRESS.id
            }
          };
          await this.changeSituationRequest(payload);
          this.toggleSnackbar({
            text: "Solicitação alterada com sucesso",
            type: "success"
          });
          this.$emit("updateList");
        } catch (error) {
          return this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  .btn-max-content {
    min-width: max-content !important;
  }

  ::v-deep .v-expansion-panel-header__icon {
    i {
      color: $primary-color-1 !important;
    }
  }
</style>
