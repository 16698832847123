<template>
  <v-container class="content">
    <title-header :title="title" />
    <div v-if="collectionDetail">
      <collection-detail-info :data="collectionDetail" />
      <table-schedule-detail
        @updateList="updateList"
        :data="collectionDetail.collectionRequests"
      />
    </div>
  </v-container>
</template>
<script>
  import { mapActions } from "vuex";

  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import CollectionDetailInfo from "@/modules/collection/views/components/CollectionDetailInfo";
  import TableScheduleDetail from "@/modules/collection/views/components/TableScheduleDetail";

  export default {
    name: "CollectionDetail",

    components: {
      TitleHeader,
      CollectionDetailInfo,
      TableScheduleDetail
    },
    data: () => ({
      collectionDetail: null
    }),
    computed: {
      title() {
        return `Detalhamento do agendamento ${this.$route.params.id}`;
      }
    },
    async mounted() {
      await this.getScheduleDetailMethod();
    },
    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("agenda", ["getScheduleDetail"]),

      async getScheduleDetailMethod() {
        try {
          const { data } = await this.getScheduleDetail(
            `${this.$route.params.id}?requestsGroupedByCity=true`
          );

          this.collectionDetail = data;
        } catch (err) {
          this.toggleSnackbar({
            text: err?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      async updateList() {
        await this.getScheduleDetailMethod();
      }
    }
  };
</script>
<style lang="scss" scoped></style>
