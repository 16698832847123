<template>
  <v-dialog v-model="state" scrollable :persistent="true" width="488">
    <v-card class="modal">
      <v-card-title class="modal-title">{{ title }}</v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <div class="pt-5">
          <p>
            Ao iniciar essa coleta você deve atualizá-la até a conclusão
          </p>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex flex-wrap justify-space-between">
        <v-btn
          :block="$isMobile"
          class="mt-1 cancel-button black-3--text"
          color="#ffffff"
          @click="backBtn"
          >Cancelar</v-btn
        >
        <v-btn
          :block="$isMobile"
          type="button"
          @click="startCollection"
          color="secondary-color-1"
          class="ma-0 mt-1 next-step-button white--text"
          >Confirmar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import masksMixin from "@/modules/core/mixins/masksMixin";

  export default {
    name: "StartCollectionDialog",
    mixins: [masksMixin],
    props: {
      request: {
        required: true,
        type: Object
      },
      backBtn: {
        type: Function,
        required: true
      },
      state: {
        required: true,
        type: Boolean
      }
    },
    computed: {
      title() {
        return `Você deseja iniciar a coleta ${this.request.batch.batchNumber}?`;
      }
    },
    methods: {
      startCollection() {
        this.$emit("startCollection", this.request.id);
        this.backBtn();
      }
    }
  };
</script>
<style></style>
