export const requestSituation = {
  AWAIT_SCHEDULE: {
    id: 1,
    descricao: "Aguardando Agendamento"
  },
  CANCELED: {
    id: 2,
    descricao: "Cancelado"
  },
  DONE: {
    id: 3,
    descricao: "Concluído"
  },
  RESCHEDULE_REQUESTED: {
    id: 4,
    descricao: "Solicitação de Reagendamento"
  },
  WAITING_COLLECTION: {
    id: 5,
    descricao: "Aguardando Coleta"
  },
  IN_PROGRESS: {
    id: 6,
    descricao: "Em andamento"
  }
};

export const optionsRequestSituationToChange = {
  CANCELED: {
    id: 2,
    descricao: "Cancelado"
  },
  DONE: {
    id: 3,
    descricao: "Concluído"
  },
  RESCHEDULE_REQUESTED: {
    id: 4,
    descricao: "Solicitação de Reagendamento"
  }
};
