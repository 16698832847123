<template>
  <div class="d-flex flex-column fill">
    <btn-dashed
      title="Importe fotos da sua galeria"
      block
      :btnAction="handleContainerClick"
    />

    <v-file-input
      style="display: none;"
      ref="fileInput"
      hide-input
      @change="handleChange"
    ></v-file-input>

    <div class="card-wrapper d-flex mt-1">
      <v-card
        elevation="n-3"
        class="img-container mt-3 mr-2 d-flex justify-center"
        v-for="(dataUrlImage, i) in dataUrlImageList"
        :key="`img-container-${i}`"
      >
        <div class="img-edit-container">
          <div class="img-edit-button" @click="removeImage(dataUrlImage.id)">
            <v-icon>mdi-close</v-icon>
          </div>
        </div>
        <div class="img-wrapper d-flex justify-center">
          <img :src="dataUrlImage.file" alt="" />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
  import BtnDashed from "@/modules/core/views/components/BtnDashed";

  import { blobToDataUrl } from "@/modules/core/helpers/fileHelpers";

  import { mapActions } from "vuex";

  export default {
    name: "MultipleImageUpload",

    props: {
      hash: {
        type: String,
        required: false
      }
    },

    components: {
      BtnDashed
    },

    data: () => ({
      isLoading: false,

      count: 0,

      file: undefined,
      dataUrlImageList: []
    }),

    methods: {
      ...mapActions([
        "toggleSnackbar",
        "uploadFile",
        "getBlobFile",
        "removeFile"
      ]),

      addImage(file) {
        const currentID = this.count++;
        this.dataUrlImageList.push({
          id: currentID,
          file,
          data: null
        });

        return currentID;
      },

      updateImageData(id, data) {
        this.dataUrlImageList.forEach(dataUrlImage => {
          if (dataUrlImage.id === id) {
            dataUrlImage.data = data;
          }
        });

        this.$emit("input", this.dataUrlImageList);
      },

      async removeImage(id) {
        const dataUrlImage = this.dataUrlImageList.find(
          dataUrlImage => dataUrlImage.id === id
        );

        if (!dataUrlImage) {
          return;
        }

        if (dataUrlImage?.data?.hash) {
          this.removeFile(dataUrlImage.data.hash);
        }

        this.dataUrlImageList = this.dataUrlImageList.filter(
          dataUrlImage => dataUrlImage.id !== id
        );

        this.$emit("input", this.dataUrlImageList);
      },

      async handleChange($event) {
        if (this.isLoading || !$event) {
          return;
        }

        this.isLoading = true;
        try {
          const imgID = this.addImage(await blobToDataUrl($event));

          await this.sendImage($event, imgID);
        } catch (error) {
          this.toggleSnackbar({
            text: error.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        } finally {
          this.isLoading = false;
        }
      },

      async sendImage(fileImage, imgID) {
        try {
          const payload = this.mountPayload(fileImage);
          const { data } = await this.uploadFile(payload);
          this.updateImageData(imgID, data);
        } catch (error) {
          this.toggleSnackbar({
            text: error.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      mountPayload(fileImage) {
        const payload = new FormData();

        payload.append("type", 1);
        payload.append("file", fileImage);

        return payload;
      },

      handleContainerClick() {
        const inputRef = this.$refs.fileInput;
        const innerInputRef = inputRef.$refs.input;
        innerInputRef.click();
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  $img-size: 142px;

  .card-wrapper {
    overflow-x: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .img-container {
    width: $img-size;
    height: $img-size;
    min-width: $img-size;
    min-height: $img-size;

    .img-wrapper {
      width: 100%;
      height: 100%;

      overflow: hidden;
      img {
        height: 100%;
      }
    }
  }

  .img-edit-container {
    position: absolute;
    width: 32px;
    height: 32px;
    z-index: 4;
  }

  .img-edit-button {
    position: relative;
    top: -16px;
    right: -70px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: inherit;
    border-radius: 100%;
    background-color: $white-2;
    cursor: pointer;
    border: 1px solid $black-3;

    &:hover {
      border: 1px solid $black-2;
      .v-icon {
        color: $primary-color-2;
      }
    }
  }
</style>
