<template>
  <v-dialog v-model="state" :persistent="true" width="636">
    <v-card class="modal">
      <v-card-title class="modal-title">{{ title }}</v-card-title>

      <v-divider></v-divider>

      <validation-observer>
        <v-form @submit.prevent="submit" slot-scope="{ invalid }">
          <div class="pa-5">
            <h3>Status da solicitação</h3>
            <v-row dense>
              <v-col cols="12">
                <field
                  v-model="statusSelected"
                  type="select"
                  :items="selectSituations"
                  item-text="descricao"
                  item-value="id"
                  label="Status"
                  name="status"
                  disabled
                  :isRequired="true"
                />
              </v-col>
            </v-row>

            <h3>
              Justificativa
              <span v-if="!isReasonRequired()">(Opcional)</span>
            </h3>
            <v-row dense>
              <v-col cols="12">
                <field
                  v-model="form.reason"
                  type="textarea"
                  label="Descrição"
                  name="description"
                  max="250"
                  :isRequired="isReasonRequired()"
                />
              </v-col>
            </v-row>

            <h3>Adicionar foto (Opcional)</h3>
            <v-row dense>
              <v-col cols="12">
                <multiple-image-upload class="fill" @input="setRequestPhoto" />
              </v-col>
            </v-row>
          </div>

          <v-divider></v-divider>

          <v-card-actions class="d-flex flex-wrap justify-space-between">
            <v-btn
              class="mt-1 black-3--text cancel-button"
              color="#FFF"
              :block="$isMobile"
              @click="backBtn"
              >Voltar</v-btn
            >
            <v-btn
              type="submit"
              :disabled="invalid"
              color="secondary-color-1"
              :block="$isMobile"
              class="ma-0 mt-1 white--text next-step-button"
              >Salvar</v-btn
            >
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapActions } from "vuex";
  import { ValidationObserver } from "vee-validate";

  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import Field from "@/modules/core/views/components/Field";
  import MultipleImageUpload from "@/modules/core/views/components/MultipleImageUpload";
  import { requestSituation } from "@/modules/agenda/enums/request-situation.enum";
  import { requestTypes } from "@/modules/agenda/enums/requestTypes.enum";

  export default {
    name: "ChangeSituationDialog",

    props: {
      requestId: {
        type: Number,
        required: true
      },
      statusSelected: {
        type: Number,
        required: true
      },
      backBtn: {
        type: Function,
        required: true
      },
      state: {
        type: Boolean,
        required: true
      }
    },

    mixins: [MasksMixin],

    components: {
      ValidationObserver,
      MultipleImageUpload,
      Field
    },

    computed: {
      selectSituations() {
        return Object.values(requestSituation);
      },
      title() {
        return `Lote ${
          this.request?.batch?.batchNumber
        } - ${this.getDonatorName(this.request)}`;
      }
    },

    data: () => ({
      form: {
        pictures: []
      },
      request: {}
    }),

    async mounted() {
      await this.initRequest();
    },

    methods: {
      ...mapActions("agenda", ["getRequestById", "changeSituationRequest"]),
      ...mapActions(["toggleSnackbar"]),

      isReasonRequired() {
        if (
          this.statusSelected === requestSituation.CANCELED.id ||
          this.statusSelected === requestSituation.RESCHEDULE_REQUESTED.id
        ) {
          return true;
        }
        return false;
      },

      getDonatorName(request) {
        if (request?.type?.id === requestTypes.PEV.id) {
          return request.voluntaryDeliveryPoint?.partner?.companyName;
        }

        return request?.donator?.donatorPF
          ? request.donator?.donatorPF?.name
          : request.donator?.donatorPJ?.fantasyName;
      },

      setRequestPhoto(dataUrlImageList) {
        this.form.pictures = dataUrlImageList.map(
          dataUrlImage => dataUrlImage.data.id
        );
      },

      async initRequest() {
        const { data } = await this.getRequestById(this.requestId);

        this.request = data;
      },

      preparePayload() {
        return {
          id: this.requestId,
          payload: {
            ...this.form,
            id: this.statusSelected
          }
        };
      },

      async submit() {
        try {
          const payload = this.preparePayload();
          await this.changeSituationRequest(payload);
          this.backBtn();
          this.toggleSnackbar({
            text: "Solicitação alterada com sucesso",
            type: "success"
          });
          this.$emit("updateList");
        } catch (error) {
          return this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>
<style lang="scss" scoped></style>
