<template>
  <div>
    <v-card>
      <div class="pa-2">
        <v-row align="center" class="ma-2 mt-1" no-gutters>
          <v-col
            cols="12"
            lg="3"
            md="4"
            sm="6"
            class="d-flex align-center pa-0"
          >
            <span class="font-weight-medium">Motorista:</span>
            <user-chip class="ml-2" :user="userFormatted" />
          </v-col>

          <v-col cols="12" lg="3" md="2" sm="6" class="pa-0">
            <span class="font-weight-medium"> Data: </span>
            <span class="schedule-detail">{{
              data.scheduleDate | formatDate
            }}</span>
          </v-col>

          <v-col cols="12" lg="3" md="2" sm="6" class="pa-0">
            <span class="font-weight-medium"> Turno: </span>
            <span class="schedule-detail">{{ data.shift }}</span>
          </v-col>

          <v-col cols="12" lg="3" md="2" sm="6" class="pa-0">
            <span class="font-weight-medium"> Coletas: </span>
            <span class="schedule-detail">{{
              data.amountOfCollectionRequests
            }}</span>
          </v-col>
        </v-row>

        <v-row no-gutters class="ma-2" align="center">
          <v-col class="pa-0" cols="12" lg="3" md="4" sm="6">
            <span class="font-weight-medium"> CRC: </span>
            <span class="schedule-detail">{{ data.crc.description }}</span>
          </v-col>

          <v-col class="pa-0" cols="12" lg="3" md="4" sm="6">
            <div class="primary-color-1--text list-content--text">
              <span> Observação: </span>
              <span class="schedule-detail">{{ data.observation }}</span>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
  import UserChip from "@/modules/account/user/views/components/UserChip";
  import { requestSituation } from "@/modules/agenda/enums/request-situation.enum";
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  export default {
    name: "CollectionDetailInfo",

    components: {
      UserChip
    },

    props: {
      data: {
        required: true,
        type: Object
      }
    },

    mixins: [MasksMixin],

    computed: {
      selectSituations() {
        return Object.values(requestSituation);
      },
      userFormatted() {
        return {
          fullName: `${this.data.driver?.firstName} ${this.data.driver?.surname}`,
          photo: this.data.driver?.photoHash
        };
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  @include search-bar();

  .title-card {
    color: $black-1;
    opacity: 1;
    font-size: 16px;
    font-weight: bold;
  }

  .schedule-detail {
    font-size: 14px;
  }
</style>
